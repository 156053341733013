// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anticipation-index-js": () => import("./../src/pages/anticipation/index.js" /* webpackChunkName: "component---src-pages-anticipation-index-js" */),
  "component---src-pages-apresentation-index-js": () => import("./../src/pages/apresentation/index.js" /* webpackChunkName: "component---src-pages-apresentation-index-js" */),
  "component---src-pages-backoffice-index-js": () => import("./../src/pages/backoffice/index.js" /* webpackChunkName: "component---src-pages-backoffice-index-js" */),
  "component---src-pages-company-index-js": () => import("./../src/pages/company/index.js" /* webpackChunkName: "component---src-pages-company-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-footer-index-js": () => import("./../src/pages/footer/index.js" /* webpackChunkName: "component---src-pages-footer-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-numbers-index-js": () => import("./../src/pages/numbers/index.js" /* webpackChunkName: "component---src-pages-numbers-index-js" */),
  "component---src-pages-plan-index-js": () => import("./../src/pages/plan/index.js" /* webpackChunkName: "component---src-pages-plan-index-js" */),
  "component---src-pages-privacypolicy-index-js": () => import("./../src/pages/privacypolicy/index.js" /* webpackChunkName: "component---src-pages-privacypolicy-index-js" */),
  "component---src-pages-styles-global-global-js": () => import("./../src/pages/styles/global/global.js" /* webpackChunkName: "component---src-pages-styles-global-global-js" */),
  "component---src-pages-summary-index-js": () => import("./../src/pages/summary/index.js" /* webpackChunkName: "component---src-pages-summary-index-js" */),
  "component---src-pages-terms-index-js": () => import("./../src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */),
  "component---src-pages-video-index-js": () => import("./../src/pages/video/index.js" /* webpackChunkName: "component---src-pages-video-index-js" */)
}

